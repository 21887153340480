import React from "react"
import { Link } from "gatsby"
import Layout from "./../components/layouts/Default"
import RequestLeftCol from "./../components/layouts/partials/pages/request/RequestLeftCol"

export default function Page({ data, location }) {
  const svg = {
    emoji: (
      <svg
        id="emoji"
        width="64"
        height="64"
        viewBox="0 0 72 72"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="hair" />
        <g id="skin">
          <path
            fill="#FCEA2B"
            d="M18.6575,19.2409c-0.9683-1.6927-3.1256-2.2799-4.8183-1.3115c-1.6927,0.9684-2.2799,3.1256-1.3115,4.8183 c0.1552,0.2714,0.3458,0.5209,0.5667,0.742l11.5324,15.0998l2.6883,3.3878l-7.8906-10.3314 c-0.9683-1.6927-3.1256-2.2799-4.8183-1.3115s-2.2799,3.1256-1.3115,4.8183c0.1552,0.2714,0.3458,0.5209,0.5667,0.7421 L21.752,46.226l6.2707,7.899c5.467,6.2731,14.5147,5.9306,20.7863,0.465c3.6045-3.1684,5.9226-7.5482,6.5154-12.3105 c0.3858-4.2326,0.807-15.301,0.807-15.301c-0.1826-2.6008-3.1353-4.5234-3.5158-3.1802l-4.8939,9.7575l-3.3657-4.2224 l3.3657,4.2224l-3.3657-4.2224L30.8909,12.1248c-0.9683-1.6927-3.1256-2.2799-4.8183-1.3115s-2.2799,3.1256-1.3115,4.8183 c0.1552,0.2714,0.3458,0.5209,0.5667,0.742l4.2488,5.5631L36,30.4167L22.581,12.7366c-0.9683-1.6927-3.1256-2.2799-4.8183-1.3115 s-2.2799,3.1256-1.3116,4.8183c0.1552,0.2714,0.3458,0.5209,0.5667,0.7421L31.6894,36"
          />
        </g>
        <g id="skin-shadow" />
        <g id="color" />
        <g id="line">
          <path
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M18.6575,19.2409 c-0.9683-1.6927-3.1256-2.2799-4.8183-1.3115c-1.6927,0.9684-2.2799,3.1256-1.3115,4.8183 c0.1552,0.2714,0.3458,0.5209,0.5667,0.742l11.5324,15.0998l2.6883,3.3878l-7.8906-10.3314 c-0.9683-1.6927-3.1256-2.2799-4.8183-1.3115s-2.2799,3.1256-1.3115,4.8183c0.1552,0.2714,0.3458,0.5209,0.5667,0.7421 L21.752,46.226l6.2707,7.899c5.467,6.2731,14.5147,5.9306,20.7863,0.465c3.6045-3.1684,5.9226-7.5482,6.5154-12.3105 c0.3858-4.2326,0.807-15.301,0.807-15.301c-0.1826-2.6008-3.1353-4.5234-3.5158-3.1802l-4.8939,9.7575l-3.3657-4.2224 l3.3657,4.2224l-3.3657-4.2224L30.8909,12.1248c-0.9683-1.6927-3.1256-2.2799-4.8183-1.3115s-2.2799,3.1256-1.3115,4.8183 c0.1552,0.2714,0.3458,0.5209,0.5667,0.742l4.2488,5.5631L36,30.4167L22.581,12.7366c-0.9683-1.6927-3.1256-2.2799-4.8183-1.3115 s-2.2799,3.1256-1.3116,4.8183c0.1552,0.2714,0.3458,0.5209,0.5667,0.7421L31.6894,36"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M11.6726,42.8719c0,2.5663,1.747,4.6428,3.9059,4.6428"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M7.0614,42.4369c0,5.5959,3.8094,10.1241,8.5171,10.1241"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M45.2619,21.2377c0-2.5663-1.747-4.6428-3.9059-4.6428"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M49.8731,21.6727c0-5.5959-3.8094-10.1241-8.5171-10.1241"
          />
        </g>
      </svg>
    ),
  }

  return (
    <Layout
      title="Bestätige Dein Abonnement in Deinem Postfach"
      description="Vielen Dank für Deine Newsletter-Anmeldung. Damit wir überprüfen können, ob Du Deine echte E-Mail-Adresse angegeben hast, bestätige bitte die Anmeldung in Deinem Postfach.."
      location={location}
      smallFooter
    >
      <section>
        <div className="flex flex-wrap">
          <div className="w-full max-w-screen-lg p-8 lg:w-8/12 lg:order-2 lg:p-16">
            <div className="flex flex-wrap">
              <div className="w-full mb-5 lg:hidden">
                <Link to="/" className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-3"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#1939ec"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="12" cy="12" r="9" />
                    <line x1="8" y1="12" x2="16" y2="12" />
                    <line x1="8" y1="12" x2="12" y2="16" />
                    <line x1="8" y1="12" x2="12" y2="8" />
                  </svg>{" "}
                  Zur Startseite
                </Link>
              </div>
              <div className="w-full mb-5">
                <h1 className="mb-5 text-2xl font-bold md:text-3xl lg:text-4xl">
                  {svg.emoji} Bestätige Dein Abonnement in Deinem Postfach
                </h1>
                <p>
                  Vielen Dank für Deine Newsletter-Anmeldung. Damit wir
                  überprüfen können, ob Du Deine echte E-Mail-Adresse angegeben
                  hast, bestätige bitte die Anmeldung in Deinem Postfach.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-8 bg-gray-100 lg:w-4/12 lg:order-1 lg:p-16 ">
            <RequestLeftCol />
          </div>
        </div>
      </section>
    </Layout>
  )
}
